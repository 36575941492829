/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import logoImage from '../images/lap-logo.png'

import React from "react"
import Helmet from "react-helmet"

const schemaOrgCorporation = {
  "@context" : "http://schema.org",
  "@type" : "Corporation",
  name : "LAP Arquitectos Asociados",
  url : "https://www.lap-arquitectos.com/",
  logo : {
    "@type":"ImageObject",
    url: logoImage
  },
  contactPoint: {
    "@type": "ContactPoint",
    telephone: "+34 957 411 705",
    contactType: "customer service"
  },
  sameAs: [
    "https://www.facebook.com/LAParquitectos/",
    "https://www.instagram.com/lap_arquitectos/",
    "https://twitter.com/lap_arquitectos",
    "https://es.linkedin.com/in/laparquitectos"
  ]
}

function SEO({
  title,
  description,
  image,
  pageurl,
  lang,
  meta,
  schemaOrg}) {
  // if(!pageData){
  //   pageData = {}
  // }
  //onsole.log(seoData)
  if(!schemaOrg){
    schemaOrg = []
  }
  if(!meta){
    meta = []
  }
  schemaOrg.push(schemaOrgCorporation)
  // const title = seoData.title
  // const description = seoData.description
  // const image = seoData.image
  // const pageurl = seoData.pageurl
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://www.lap-arquitectos.com${pageurl}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'Luis Muñoz',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrg, null, '\t')}
      </script>
    </Helmet>
  )
}


export default SEO
